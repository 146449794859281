<template>

  <div v-if="scalapay.enabled" style="margin-top:7px;">

    <scalapay-widget
        min="5"
        :max="scalapay.maxAmount"
        :amount="mixinRoundTwoDigits(amount)"
        :size="size"
        :logoSize="logoSize"
        priceColor="#145ad1"
        logoColor="#000000"
        logoAlignment=""
        :numberOfPayments="scalapay.numberOfPayments"
        :hideLogo="hideLogo"
        :hidePrice="hidePrice"
        locale="it"
    >
    </scalapay-widget>

  </div>

</template>

<script>
import Vue from "vue";

export default {
  //name: 'scalapay-widget',
  data(){
    return {
      scalapay: Vue.prototype.$config.guiSettings.integrations.scalapay
    }
  },
  props:{
    amount: {
      type: String,
      default: '0',
    },
    size:{
      type: String,
      default: '100px',
    },
    logoSize:{
      type: String,
      default: '100%',
    },
    hideLogo:{
      type: String,
      default: 'false',
    },
    hidePrice:{
      type: String,
      default: 'false',
    },
  },
}
</script>