<template>
    <div id="property-prices" :class="'property-prices '+mixinGetIntegrationInfo(live_price).class">
        <div class="property__resume">

            <span :class="'property-prices-total'">€ {{mixinRoundTwoDigits(live_price)}}</span>

            <div
                v-if="config.guiSettings.hasTransports"
                class="property-prices-package"
                v-b-popover.hover.top="'Conveniente perchè hai acquistato un volo su fly by VadoBay'"
            >
              <SvgFlight class="property-prices-package-icon-flight" />
              <span>Tariffa package</span>
              <SvgInfoCircle class="property-prices-package-icon-info" />
            </div>

            <div v-if="mixinGetIntegrationInfo(live_price).canCheckout">
                <span class="property-prices-alert" :id="'popover-id-'+uniqueId">
                    <p v-if="mixinGetIntegrationInfo(live_price).class=='gold'" class="goldenize">
                        <span>Acquistabile con<br>carta di credito</span>
                        &nbsp;<SvgInfoCircle />
                    </p>
                </span>
                <!--
                <b-popover :target="'#popover-id-'+uniqueId" triggers="hover" placement="top">
                    <div class="d-flex justify-content-between">
                        <span v-if="mixinGetIntegrationInfo(live_price).integration == 'jointly'">
                            Potrai prenotare questa soluzione pagando interamente con la tua carta di credito sul portale Jointly
                        </span>
                        <span v-else>
                            Potrai integrare la differenza usando la tua carta di credito
                        </span>
                    </div>
                </b-popover>
                -->
            </div>            

            <div v-else>
                <span class="property-prices-alert" :id="'popover-id-'+uniqueId">
                    <p v-if="mixinGetIntegrationInfo(live_price).class=='gold'" class="goldenize">Acquistabile con carta di credito</p>
                    <p v-else>
                        <span v-if="mixinIsWelfare()">Credito welfare</span><span v-else>Credito</span><br>non sufficiente <SvgInfoCircle />
                    </p>
                </span>
                <!--
                <b-popover :target="'#popover-id-'+uniqueId" triggers="hover" placement="top">
                    <div class="d-flex justify-content-between">
                        <span>
                            Il tuo attuale <span v-if="mixinIsWelfare()">credito welfare</span><span v-else>credito</span> non è sufficiente a prenotare questa soluzione. Non sono possibili integrazioni con carta di credito.
                        </span>
                    </div>
                </b-popover>
                -->
            </div>
            <span class="property-prices-per-night">
                € {{mixinRoundTwoDigits(live_price/dates.nights)}} per notte
            </span>


            <ScalapayWidget
                :amount="mixinRoundTwoDigits(live_price)"
                :size="'80px'"
                :logoSize="'80%'"
            />


        </div>
        <hr>
        <div class="property-prices-occupancy">
            <span class="nowrap">
                {{dates.nights}} <span v-if="dates.nights == 1">Notte</span><span v-else>Notti</span>
            </span>
            <span class="nowrap">
                {{occupancy.adults}} <span v-if="occupancy.adults == 1">Adulto</span><span v-else>Adulti</span>
                <span v-if="occupancy.children.length">
                -
                {{occupancy.children.length}} <span v-if="occupancy.children.length == 1">Bambino</span><span v-else>Bambini</span>
                </span>
            </span>
        </div>
        <hr>

        <b-button variant="cta" v-if="instantiatedOnRoute == 'search'"
            @click="$emit('go-to-property',{})"
        >
            {{btnText}}
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 1.63794L7.15601 7.29504L1.5 12.951" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </b-button>

        <b-button variant="cta" v-if="instantiatedOnRoute == 'property'"
            @click="(!refundable && mixinShowNonRefundableModal()) ? $bvModal.show('nonRefundableDisclaimerModal'+uniqueId) : $emit('room-selected',{})"
            :disabled="disabledButton" data-e2e="btnScopri"
        >
            {{btnText}}
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 1.63794L7.15601 7.29504L1.5 12.951" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </b-button>


        <br>

        <PropertyModalNonRefundableDisclaimer
            :modalId="'nonRefundableDisclaimerModal'+uniqueId"
            v-on:modal-ok="trackNonRefundableModalClose('ok'); $emit('room-selected',{})"
            v-on:modal-ko="$bvModal.hide('nonRefundableDisclaimerModal'+uniqueId); trackNonRefundableModalClose('ko');"
        />
    </div>
</template>

<script>
    import PropertyModalNonRefundableDisclaimer from '@/components/blocks/property/property-modal-non-refundable-disclaimer'
    import SvgInfoCircle from '../../svg/info-circle'
    import SvgFlight from '../../svg/flight'
    import ScalapayWidget from '@/components/blocks/scalapay-widget'
    import Vue from "vue";

    export default {
        name: 'property-prices',
        components:
        {
            PropertyModalNonRefundableDisclaimer,
            SvgInfoCircle,
            SvgFlight,
            ScalapayWidget,
        },
        props:
        {
            live_price: String,
            btnText: String,
            additionalInfo: Object,
            disableUnavailable: Boolean,
            uniqueId: String,
            refundable: Boolean,
            instantiatedOnRoute: {
              type: String,
              default: 'search',
            },
        },
        data(){
          return {
            config: Vue.prototype.$config,
          }
        },
        computed: {
            availability: {
                get() { return this.$store.state.integration.availability },
                set() {}
            },
            occupancy: {
                get() { return this.$store.state.occupancy },
                set(value) { this.mixinSendMutation('setOccupancy',value) }
            },
            dates: {
                get() { return this.$store.state.dates },
                set(value) { this.mixinSendMutation('setDates',value) }
            },
            disabledButton: {
                get() { return ((this.$route.name !== 'search') && !this.mixinGetIntegrationInfo(this.live_price).canCheckout) },
                set(){}
            },
        },
        methods:
        {
          trackNonRefundableModalClose(label){
            // label: ok / ko
            if(process.env.VUE_APP_MODE != 'production') window.console.log('trackNonRefundableModal: closed ' + label);

            if(this.$gtm) this.$gtm.trackEvent({
              event: 'NonRefundableModal',
              category: 'userBehaviour',
              action: 'closed',
              label: label,
              value: 0,
            });

          }
        },
    }
</script>

<style lang="scss">
    .property-prices
    {
        display: flex;
        flex-wrap:wrap;
        align-items: center;
        text-align:center;
        width: 100%;
        flex-direction:column;
        
        @include media-breakpoint-up(md)
        {
            
        }
        .property__resume
        {
            width:70%;
            display:flex;
            flex-direction: column;
        }
        hr
        {
            border-top:1px solid $gray-300;
            width:80%;
            margin:0.75rem auto;
        }
        button
        {
            width:100%;
            font-size: 13px;
            margin-top: 1rem;
            font-weight: 600;
            padding-top:10px;
            padding-bottom:10px;
            font-weight:400;
            width:100%;
            position:relative;
            padding-left: 0;
            padding-right: 0;

            svg
            {
                position:absolute;
                right:15px;
                top:50%;
                transform:translateY(-50%);
            }
        }

        &-occupancy
        {
            width:30%;
            color:rgba($smooth-black,0.5);
            font-size:12px;
            display:flex;
            flex-direction:column;
        
            @include media-breakpoint-up(md)
            {
                width:100%;
                margin-top: 0;
            }
        }
        &-total
        {
            @include customize-color("cta");
            @extend [custom-color-primary] !optional;
            font-size:22px;
            font-weight:600;
            @include media-breakpoint-up(xl)
            {
                font-size:30px;
            }
        }
        &-package
        {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          cursor: default;

          &-icon
          {
            &-flight
            {
              width:19px;
              margin-right: 2px;
              transform: rotate(50deg);
              fill: $gray-600;
            }
            &-info
            {
              width:12px;
              margin-left: 5px;
            }
          }
        }
        &-per-night
        {
            color:$black;
            font-size:12px;
            font-weight:400;
        }
        &-alert
        {
            color:$danger;
            font-size:12px;
            font-weight:400;
            font-style:italic;
            line-height:normal;
            display: inline-block;
            margin-top:8px;
            svg
            {
                width:14px;
                margin-left:3px;
                * {
                    fill:$danger;
                }
            }
            .goldenize
            {
                color:$gold;
                svg
                {
                    width:14px;
                    margin-left:3px;
                    * {
                        fill:$gold;
                    }
                }
            }
        }

        .danger
        {
            color:$danger;
        }
        
        .btn[disabled]
        {
            background:$gray-400;
            border-color: transparent;
            color:$gray-600;
            cursor:initial;
            svg path
            {
                stroke: $gray-600;
            }
        }
    }

    //flagWelfare
    .goldenize
    {
        color:$gold;
        svg *
        {
            fill:$gold;
        }
    }

    .default .rate__right,
    .goldenize .rate__right
    {
        .property-prices
        {
            &-total,
            &-alert
            {
                @include customize-color("tertiarty");
            }
            &-alert svg *
            {
                @include customize-fill("tertiarty");
            }
        }
    }
    .red .rate__right
    {
        .property-prices
        {
            &-total,
            &-alert
            {
                @include customize-color("danger");
            }
            &-alert svg *
            {
                @include customize-fill("danger");
            }
        }
    }    
</style>