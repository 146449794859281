<template>
    <b-modal :id="modalId" size="lg" class="propertyModalNonRefundableDisclaimer">
        <template v-slot:modal-title>
            Prenotazione non rimborsabile
        </template>
        <div class="modal-section">
            <p>
              Questa prenotazione <b>non dà diritto a rimborso</b> in caso di cancellazione e non è modificabile.
            </p>
            <p>
              In caso di problemi a viaggiare per qualsiasi motivazione, anche legata al Covid, <b>la possibilità di ottenere un rimborso è demandata alla volontà della struttura</b>. Molte strutture adottano una linea restrittiva.
            </p>
            <p>
              Ti consigliamo di scegliere <b>soluzioni di tipo Rimborsabile</b>.
            </p>
        </div>
        <template v-slot:modal-footer>
            <div class="modal-prices-footer text-center m-auto">
                <b-button class="ml-1 mr-1" variant="success" @click="$emit('modal-ko',{})">Ok! Scelgo altro</b-button>
                <b-button class="ml-1 mr-1 btn-gray" variant="warning" @click="$emit('modal-ok',{})">Ho capito, vado avanti</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: 'propertyModalNonRefundableDisclaimer',
    props:
    {
        modalId: String,
    }
}
</script>