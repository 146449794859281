<template>
    <div :class="'lite__favorite lite__' + variant + ' ' + (favorite ? 'active' : '') + ' lite-tooltip'" v-on:click.stop.prevent="addOrRemoveFavorite" :data-text="(variant == 'compact')? liteTooltipCopy : ''"><!-- tooltip solo compact -->
        <div class="lite__icon" ><font-awesome-icon icon="heart"/></div>
        <div class="lite__copy">
            <span v-if="favorite">preferito</span>
            <span v-else>aggiungi a preferiti</span>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import User from '@/user'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
library.add(faHeart)
Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
    name: 'favorite',
    props:{
        property: Object,
        variant:
        {
            type: String,
            default: 'full' // 'compact' | 'full'
        },
    },
    data()
    {
        return {
            favorite: false,
        }
    },
    computed:
    {
        favoritesLength: function() {
            return this.$store.state.favorites.properties.length
        },
        liteTooltipCopy: function(){
          if(this.favorite) return 'Tra i tuoi preferiti';
          return 'Aggiungi ai preferiti'
        }
    },
    watch:
    {
        favoritesLength: function()
        {
            this.checkIsFavorite()
        }
    },
    mounted()
    {
        this.checkIsFavorite()
    },
    methods:
    {
        checkIsFavorite()
        {
            let favorites = this.$store.state.favorites,
                _this = this

            if(favorites.properties == undefined)
            {
                this.favorite = false
                return
            }

            let findFavorite = favorites.properties.filter(function(property){
                    return property.property.propertyId == _this.property.propertyId
                }).length
            
            this.favorite = (findFavorite !== 0)
        },
        addOrRemoveFavorite()
        {
            this.favorite ? this.removeFavorite() : this.addFavorite()
        },
        addFavorite()
        {
            User.addToFavorites({
                type: 'property',
                value: this.property
            })

            this.checkIsFavorite()
        },
        removeFavorite()
        {
            User.removeFromFavorites({
                type: 'property',
                value: this.property
            })

            this.checkIsFavorite()
        }
    }
}
</script>

<style lang="scss">
    
    
    
    .favorite
    {
    }
</style>