<template>
    <div class="property-pad__rates">
        <SvgOk />
        <span class="property-pad__scoredesc">{{score_description}}</span>
        <span class="score">{{Number((salabamScore/20).toFixed(1))}}/5</span>
        <!--
        <span class="property-pad__scorestars">
            <Stars :hotelRating="parseFloat(hotelRating)" />
        </span>
        -->
    </div>
</template>

<script>
    import SvgOk from '../../svg/ok'
    //import Stars from '../../atoms/stars'

    export default {
        name: 'property-rates',
        components:
        {
          SvgOk,
          //Stars
        },
        props:
        {
          score_description: String,
          salabamScore: String
        }
    }
</script>

<style lang="scss">
    
    
    
    

    .property-pad__rates
    {
        margin:0 auto;
        margin-top:8px;
        margin-bottom:0;
        font-size:15px;
        font-weight:500;
        align-items: center;
        $icon-width:70px;
        position:relative;
        display:flex;
        align-items: center;
        @include customize-color("cta");
        &>svg
        {
            width:15px;
            height:auto;
            margin-right:11px;
            transform:translateY(-1px);
            path
            {
                @include customize-fill("cta");
            }
        }            
        .property-pad__scoredesc
        {
            //padding:10px 0;
            font-weight:500;
            //text-transform: uppercase;
            font-size:14px;
            margin-right:12px;
        }
        .property-pad__scoreicon
        {
            .score
            {
                position:absolute;
                left:0;
                right:0;
                text-align:center;
                top:50%;
                transform:translateY(-50%);
            }
        }
        
        @include media-breakpoint-up(lg)
        { 
        }
    }
</style>